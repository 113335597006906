@use '../../../components/GlobalStyles/abstracts/' as abs;
.cart-wrap {
    border-radius: 10px;
    background: #fff;
    overflow: hidden;
    .item {
        position: relative;
        height: 62px;
        padding: 0 16px;
        transition: background-color 0.1s;
        border-bottom: 1px solid #ccc;
        gap: 1px;
        height: 100%;
        cursor: pointer;
        padding: 14px;
        transition: ease 0.3s;
        &:hover {
            background: #0000000d;
        }
        .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
        }
    }
}

.title {
    font-size: 2.4rem;
    margin-bottom: 10px;
}
.desc {
    font-size: 1.6rem;
    font-weight: 400;
    margin: 10px 0px;
}
.img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
.avatar {
    margin: 0 auto;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
}
.info {
    font-size: 1.4rem;
    font-weight: 400;
    color: #7b7575;
}
.upload-wrap {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 16px 20px;
    border-radius: 10px;
    background-color: #fff;
    margin-top: 20px;
    cursor: pointer;
}

.modal-wrap {
    width: 420px;
    @include abs.screen(md) {
        width: 100%;
    }
    .modal-title {
        font-size: 2.4rem;
    }
    .modal-desc {
        margin-top: 8px;
        font-size: 1.5rem;
        opacity: 0.9;
        margin-bottom: 20px;
    }
    .modal-form-group {
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;
        .modal-label {
            font-size: 1.4rem;
            font-weight: 500;
            margin: 10px 0 10px 8px;
            display: block;
        }
        .modal-input-wrap {
            position: relative;
            background: #fff;
            border: 1.5px solid #dee3e9;
            border-radius: 44px;
            overflow: hidden;
            height: 44px;
            display: flex;
            &:focus-within {
                border: 1px solid #1dbfaf;
            }
            &.error {
                background-color: #ff00001a;
                border: 1.5px solid var(--invalid-color);
            }
        }
        .modal-input {
            display: block;
            width: 100%;
            padding: 12px 42px 12px 20px;
            border: none;
            outline: none;
            font-size: 1.4rem;
            background-color: transparent;
        }
    }
    .btn {
        color: #fff;
        background-color: #1dbfaf;
        border: none;
        width: 100%;
        padding: 10px 0px;
        border-radius: 999px;
        overflow: hidden;
        &.adopt {
            cursor: not-allowed;
            opacity: 0.4;
            &:hover {
                opacity: 0.4;
            }
        }
        &:hover {
            opacity: 0.7;
        }
    }
    .btn-upload {
        color: #fff;
        background-color: #1dbfaf;
        border: none;
        width: 100%;
        padding: 10px 0px;
        border-radius: 999px;
        overflow: hidden;
        &.adopt {
            cursor: not-allowed;
            opacity: 0.4;
            &:hover {
                opacity: 0.4;
            }
        }
        &:hover {
            opacity: 0.7;
        }
    }
}
.error-wrap {
    animation: slide ease 0.3s;
    .error-item {
        display: flex;
        align-items: center;
    }
}
.error-text {
    margin: 8px 0 8px 8px;
    color: var(--invalid-color);
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.5;
}
.error-message {
    display: flex;
    align-items: center;
    gap: 10px;
    color: red;
    animation: slide ease 0.3s;
    margin-bottom: 10px;
}
.eye-icon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
}
@keyframes slide {
    0% {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-4px);
    }
    100% {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
    }
}
