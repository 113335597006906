@use '../../../components/GlobalStyles/abstracts/' as abs;

.wrap-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.cost,
.private,
.public {
    font-size: 2.4rem;
    font-weight: 600;
    margin-bottom: 0px;
    @include abs.screen(sm) {
        font-size: 2rem;
    }
}
.head-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.link {
    font-size: 1.4rem;
    font-weight: 500;
    color: red !important;
    &:hover {
        text-decoration: underline;
    }
}
.sub {
    font-size: 1.4rem;
    font-weight: 400;
    color: #fff;
    padding: 8px;
    border-radius: 10px;
    background: rgb(0, 189, 255);
    background: linear-gradient(85deg, rgba(0, 189, 255, 0.6727941176470589) 0%, rgba(0, 237, 255, 1) 100%);
}
.wrap {
    display: flex;
    align-items: center;
    gap: 10px;
}
