.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    min-width: 100px;
    background-color: transparent;
    padding: 9px 20px;
    font-size: 1.6rem;
    font-weight: 500;
    border-radius: 9999px;
    cursor: pointer;
    gap: 10px;
}
.primary {
    transition: opacity 0.25s;
    opacity: 1;
    flex-shrink: 0;
    color: #fff;
    background: linear-gradient(to right bottom, #ff8f26, #ff5117);
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
}
.save {
    background-color: #6597fe;
    color: #fff;
}
.text {
    transition: ease 0.4s;
    &:hover {
        color: var(--primary-color);
    }
}
.disable {
    opacity: 0.5;
    cursor: not-allowed !important;
    &:hover {
        opacity: 0.5;
    }
}
