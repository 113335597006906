.wrap {
    display: flex;
    flex-direction: column;
    max-width: 860px;
    margin: 0 auto;
}
.btn {
    display: inline-block;
    width: 100px;
    margin-bottom: 20px;
    margin-left: auto;
}
