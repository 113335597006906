@use '../../components/GlobalStyles/abstracts/' as abs;
.note {
    cursor: pointer;
    .icon {
        color: #706e6e;
        &:hover {
            color: #000;
        }
    }
}
.note.white {
    .icon:hover {
        color: #fff;
    }
}

.note-content {
    position: fixed;
    display: flex;
    justify-content: flex-end;
    width: 43%;
    min-width: 800px;
    max-width: 100%;
    height: 100vh;
    transition: 0.3s ease;
    z-index: 1000;
    top: 0;
    right: 0;
    transform: translateX(100%);
    opacity: 0;
    visibility: hidden;
    @include abs.screen(lg) {
        width: 80%;
        height: 100vh;
        min-width: 0;
    }
    @include abs.screen(md) {
        width: 100%;
        min-width: 0;
    }
}
.note-content.show {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
}
.content {
    color: #fff;
    flex: 1;
    background-color: #272a31;
    box-shadow: rgb(0 0 0 / 15%) 0px 4px 12px;
    cursor: default;
    padding: 32px;
    overflow-x: hidden;
    overflow-y: auto;
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include abs.screen(sm) {
            align-items: flex-start;
        }
        .note-title {
            margin: 0px;
            font-size: 2.4rem;
            font-weight: 700;
            letter-spacing: 0.5px;
            @include abs.screen(sm) {
                font-size: 2rem;
            }
        }
        .create-wrap {
            height: 38px;
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            padding: 6px 10px;
            background-color: #323c4a;
            border: 2px solid #323c4a;
            cursor: pointer;
            user-select: none;
            transition: ease 0.4s;
            border: 1px solid transparent;
            &:hover {
                border: 1px solid #ccc;
            }
            .create-icon {
                color: #dae4f0;
            }
            .create-note {
                background-color: transparent;
                color: #dae4f0;
                font-size: 2rem;
            }
        }
        .header-wrap {
            display: flex;
            align-items: center;
            gap: 16px;
            @include abs.screen(sm) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
    .xmark-icon {
        font-size: 3rem;
    }
    .x-mark {
        cursor: pointer;
    }
}
.over-lay {
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0;
    visibility: hidden;
    z-index: 10;
}
.over-lay.show {
    opacity: 0.7;
    visibility: visible;
}
.note-body {
    margin-top: 20px;
}
