.wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-bottom: 1px solid #000;
    &.active {
        background-color: #191d1e;
    }
    .decor {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
    }
    .main {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        .title {
            font-size: 1.2rem;
            font-weight: 400;
        }
        .sub-title {
            color: rgb(128, 139, 154);
            font-weight: 400;
            font-size: 1.2rem;
        }
    }
    .check {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background-color: #33343c;
        margin-left: auto;
        border-radius: 4px;
        transition: ease 0.3s;
        &:hover {
            background-color: #4a4b4f;
        }
    }
}

.alert-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    .alert-desc {
        font-size: 1.6rem;
        font-weight: 500;
    }
}
.custom-modal-title {
    font-size: 1.8rem;
    color: #ff0000;
    font-weight: bold;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
