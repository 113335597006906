.wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 10px 0px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 30px;
    .decor {
        margin-right: 10px;
        font-size: 2rem;
        font-weight: 500;
        color: red;
    }
    .img {
        width: 100%;
    }
}
.title {
    font-size: 2.4rem;
    font-weight: 600;
}
.head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.delete-icon {
    cursor: pointer;
}
.utils {
    display: flex;
    align-items: center;
    gap: 10px;
}
