@use '../../components/GlobalStyles/abstracts/' as abs;
.wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 66px);
}
.body {
    margin: 10px 0px;
    border: 1px solid #ccc;
    padding: 0px 20px;
    border-radius: 16px;
    width: 900px;
    height: 90%;
    overflow: hidden auto;
    @include abs.screen(lg) {
        width: 90%;
    }
    @include abs.screen(md) {
        width: 100%;
    }
}
