@use '../../../../components/GlobalStyles/abstracts/' as abs;

.wrap {
    width: 100vw;
    height: 100vh;
    background-color: #f6f8fc;
    display: flex;
    align-items: center;
    justify-content: center;
    .body {
        background-color: #fff;
        box-shadow: 0px 0px 40px 0px rgba(201, 198, 198, 0.75);
        -webkit-box-shadow: 0px 0px 40px 0px rgba(201, 198, 198, 0.75);
        -moz-box-shadow: 0px 0px 40px 0px rgba(201, 198, 198, 0.75);
        border-radius: 10px;
        overflow: hidden;
        height: 400px;
        width: min(1000px, 90%);
        .title {
            font-size: 3rem;
            font-weight: 500;
            text-align: center;
        }
        .form {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 10px;
            height: 100%;
            width: 100%;
        }
        .desc {
            font-size: 1.4rem;
            font-weight: 400;
            color: #474444;
        }
        .img {
            @include abs.screen(md) {
                display: none;
            }
            width: 100%;
            height: 400px;
        }
    }
    .form-wrap {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .error-message {
        color: red;
        font-size: 1.4rem;
        font-weight: 500;
    }
}
