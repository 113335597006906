@use '../../../components/GlobalStyles/abstracts/' as abs;
.wrap {
    position: relative;
    padding: 70px 14px;
    @include abs.screen(lg) {
        display: none;
        position: fixed;
        inset: 0;
        z-index: 999;
        width: 100%;
        height: 100%;
        background-color: #fff;
        padding: 10px;
    }
}
.wrap.active {
    @include abs.screen(lg) {
        display: block;
    }
}

.out {
    top: 20px;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #e3e2e2;
    width: 40px;
    height: 40px;
    cursor: pointer;
    &:hover {
        background-color: #ccc;
    }
    @include abs.screen(lg) {
        display: none;
    }
}
.x-mark {
    z-index: 9999;
    top: 20px;
    right: 0;
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #e3e2e2;
    width: 40px;
    height: 40px;
    cursor: pointer;
    &:hover {
        background-color: #ccc;
    }
    @include abs.screen(lg) {
        display: flex;
        right: 10px;
    }
}
