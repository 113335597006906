@use '../../../components/GlobalStyles/abstracts/' as abs;
.list {
    padding: 0;
    margin: 0;
}
.list-wrap {
    display: none;
    padding: 0;
    margin: 0;
    animation: fadeIn ease 0.4s;
    user-select: none;
}
.list-wrap.active {
    display: block;
}
.head-wrap {
    border-bottom: 1px solid #ccc;
    padding: 10px 20px;
    justify-content: center;
    @include abs.screen(md) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.x-mark {
    color: #000;
    display: none;
    @include abs.screen(md) {
        display: inline-block;
    }
}
.title {
    font-size: 1.8rem;
    color: #000;
    text-align: center;
    margin: 0;
}
.item,
.item-main,
.item-sub,
.lock {
    user-select: none;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    font-size: 1.6rem;
    transition: 0.2s ease;
    color: #000;
    .item-left {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .item-desc {
        font-size: 1.8rem;
        font-weight: 500;
    }
    &:hover {
        background-color: #ebeaea;
    }
    &.active {
        background-color: #cfcdcd;
    }
}
.item-sub {
    position: relative;
    padding-left: 30px;
}

.item-main {
    justify-content: space-between;
    border-bottom: 2px solid #9c9b9b;
}
.item-link {
    font-size: 1.4rem;
    font-weight: 400;
    color: #000 !important;
}
.null-alert {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}
.wrap {
    width: 23%;
    position: fixed;
    top: 50px;
    z-index: 99;
    bottom: 50px;
    left: 0;
    overflow-y: auto;
    background-color: #fff;
    color: #fff;
    transition: ease 0.4s;
    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;
    border-right: 1px solid #ccc;
    @include abs.screen(lg) {
        width: 300px;
    }
    @include abs.screen(md) {
        width: 100%;
    }
    .heading {
        padding: 20px 10px;
        text-align: center;
        font-size: 1.8rem;
        font-weight: 500;
    }
}
.wrap.show {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
}
.item-icon-main {
    width: 25px;
    height: 25px;
}
.item-icon-sub {
    width: 20px;
    height: 20px;
}

.export-btn,
.approve-btn,
.delete-btn {
    margin-top: 10px;
    width: 100%;
    color: #fff;
    background-color: #6597fe !important;
}
.delete-btn {
    background-color: red !important;
}
.btn-cancle {
    background-color: red !important;
    color: #fff;
}
.modal {
    width: 100%;
    height: 100%;
    .modal-icon {
        font-size: 4rem;
        color: rgb(206, 206, 2);
    }
    .modal-title {
        font-size: 3rem;
        font-weight: 500;
    }
    .drop-head {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-bottom: 30px;
    }
    .drop-mbody {
        margin-bottom: 20px;
        font-size: 1.8rem;
        font-weight: 400;
    }
}
.cancle-btn {
    width: 100%;
    background-color: #ccc;
    margin-top: 10px;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-4px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
