@use '../../components/GlobalStyles/abstracts/' as abs;
.banner {
    width: 100%;
    margin-top: calc(-1 * var(--header-height));
    padding-top: 28%;
    position: relative;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    background: top center / cover no-repeat;
    background-image: url('../../assets/Image/imgs/profileBanner.png');
    position: relative;
    @include abs.screen(md) {
        margin-top: 0;
    }
}
.user-banner {
    position: absolute;
    bottom: -70px;
    left: 40px;
    display: flex;
    align-items: flex-end;
    z-index: 1;
    @include abs.screen(md) {
        left: 50%;
        bottom: -100px;
        transform: translateX(-50%);
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    .avatar {
        border-radius: 50%;
        overflow: hidden;
        width: 172px;
        height: 172px;
        object-fit: cover;
        @include abs.screen(md) {
            width: 120px;
            height: 120px;
        }
        @include abs.screen(sm) {
            width: 100px;
            height: 100px;
        }
    }
    .name {
        font-size: 2.8rem;
        font-weight: 700;
        margin-left: 16px;
        margin-bottom: 10px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: flex;
        justify-content: flex-start;
        @include abs.screen(md) {
            margin-bottom: 20px;
            margin-left: 0;
            font-size: 1.8rem;
            align-items: center;
        }
    }
}

.content {
    margin-top: 90px;
    padding: 0 24px;
}

.wrapper {
    background-color: #fff;
    box-shadow: #0000001a 0 0 5px, #0000001a 0 0 1px;
    border-radius: 8px;
    padding: 18px 14px;
    margin-bottom: 20px;
    word-break: break-word;
}
// info user
.info-title {
    margin: 0 0 20px;
    font-size: 1.6rem;
    font-weight: 600;
}
.info-list {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.info-item {
    display: flex;
    flex-direction: column;
}
.info-edit {
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    transition: ease 0.2s;
    &:hover {
        background-color: #ccc;
    }
}
.head {
    display: flex;
    align-items: center;
    gap: 10px;
}
.icon {
    width: 30px;
}
.unset {
    font-size: 1.4rem;
    font-weight: 400;
    color: #686565;
}
