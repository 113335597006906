.user {
    padding: 0px 20px 20px 20px;
    .head {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 40px;
        .title {
            font-size: 3rem;
            font-weight: 500;
            color: #fff;
        }
    }
}

.select {
    position: relative;
    width: 100px;
    .custom-arrow {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
    }
}
.avatar {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
}
