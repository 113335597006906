.utils {
    display: flex;
    align-items: center;
    gap: 10px;
}
.table {
    border: 1px solid #ccc;
}
.avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}
