.wrap {
    display: flex;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    .decor {
        flex: 1;
        padding: 10px 0px;
        font-size: 1.4rem;
        font-weight: 400;
        color: #959494;
        cursor: text;
    }
}
.modal {
    display: flex;
    align-items: flex-start;
    gap: 20px;
}
.utils {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 20px;
    &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 2px;
        border-radius: 10px;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: #ebeaea;
    }
    .icon {
        position: relative;
        color: #706f6f;
        padding: 2px;
        cursor: pointer;
        transition: ease 0.3s;
        &:hover {
            color: #000;
        }
    }
}
.modal-body {
    display: flex;
    flex-direction: column;
    gap: 6px;
    flex: 1;
    width: 100%;
    overflow: hidden;
    .modal-name {
        font-size: 1.6rem;
        font-weight: 500;
    }
}
.hidden-file {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
}
.slider-wrap {
    width: 100%;
    overflow: hidden;
}
