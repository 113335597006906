@use '../../components/GlobalStyles/abstracts/' as abs;
.wrap {
    width: 100%;
    padding: 24px;
    border-radius: 16px;
    border: 2px solid #e8e8e8;
    display: flex;
    flex-direction: column;
}
.header {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 10px;
    @include abs.screen(sm) {
        flex-direction: column;
    }
    .info {
        .title {
            display: block;
            color: #242424;
            font-size: 2rem;
            font-weight: 900;
            padding-bottom: 14px;
        }
        .desc {
            height: 135px;
            margin: 0;
            font-size: 1.4rem;
            line-height: 1.6;
            @include abs.screen(xl) {
                height: 200px;
            }
            @include abs.screen(lg) {
                height: 135px;
            }
            @include abs.screen(md) {
                height: 100%;
            }
        }
    }
}
.img-wrap {
    width: 100%;
    height: 100%;
    .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.btn {
    width: 160px;
    color: #fff !important;
    @include abs.screen(sm) {
        width: 100%;
    }
}
