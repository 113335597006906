@use '../../../../components/GlobalStyles/abstracts/' as abs;

.wrap {
    box-shadow: 0px 0px 12px 0px rgba(211, 206, 206, 0.75);
    -webkit-box-shadow: 0px 0px 12px 0px rgba(211, 206, 206, 0.75);
    -moz-box-shadow: 0px 0px 12px 0px rgba(211, 206, 206, 0.75);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
    cursor: pointer;
    transition: ease 0.2s;
    @include abs.screen(lg) {
        width: 100%;
    }
    &:hover {
        transform: translateY(-8px);
    }
    .title {
        font-size: 2.6rem;
        font-weight: 500;
    }
    .desc {
        font-size: 1.6rem;
        font-weight: 400;
        color: #535151;
        text-align: center;
    }
}
