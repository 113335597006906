.blogs {
    .title {
        font-size: 2.4rem;
        font-weight: 900;
        line-height: 1.4;
        color: #242424;
        margin: 50px 0px 20px 0px;
    }
    .desc {
        color: #292929;
        word-wrap: break-word;
        font-size: 1.4rem;
        font-weight: 400;
        margin: 20px 0px 40px 0px;
    }
}

.blog {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    border-radius: 16px;
    border: 2px solid #e8e8e8;
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .utils {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: auto;
        .icon {
            cursor: pointer;
        }
    }

    body {
        .blog-title {
            color: #292929;
            font-size: 2rem;
            font-weight: 700;
            line-height: 1.6;
            margin-top: 8px;
            margin-bottom: 0;
        }
        .blog-info {
            display: block;
        }
    }
    .footer {
        display: flex;
        align-content: center;
        justify-content: flex-start;
        gap: 10px;
    }
    .blog-banner {
        width: 100%;
        height: 200px;
        border-radius: 16px;
        overflow: hidden;
        margin: 20px 0px;
        .blog-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.body-desc {
    height: 72px;
    margin-top: 4px;
    font-size: 1.5rem;
    line-height: 1.6;
    color: #505050;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* Show 3 lines */
    overflow: hidden;
    text-overflow: ellipsis;
}

.ellip-list {
    background: var(--white-color);
    box-shadow: 0 -4px 32px #0003;
    transition: all ease-in-out 0.3s;
    border-radius: 10px;
    padding-left: 0;
    margin: 0;
    overflow: hidden;
    will-change: opacity, transform;
    color: #1d2129;
    padding: 10px 0px;
    animation: fadeIn ease 0.3s;
    .ellip-item {
        transition: ease 0.3s;
        &:hover {
            color: #292929;
            background-color: #f0f0f0;
        }
    }
}
.info {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}
.info-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
}
.info-name {
    font-size: 1.6rem;
    font-weight: 500;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-8px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
