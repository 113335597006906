.footer {
    position: fixed;
    bottom: 0;
    height: 50px;
    width: 100%;
    backdrop-filter: blur(32px);
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px;
    .side-bar {
        border-radius: 50%;
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        transition: background-color 0.3s ease;
        cursor: pointer;
    }
}
