@use '../../../../components/GlobalStyles/abstracts/' as abs;
.course-wrap {
    cursor: pointer;
    .course {
        padding: 10px;
        transition: ease 0.4s;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #000;
        &:hover {
            background-color: #33343c;
        }
    }
    .course-header {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .title {
            font-size: 1.8rem;
            font-weight: 500;
        }
        .quantity {
            font-size: 1.4rem;
            font-weight: 500;
            color: #9d9fa9;
        }
    }
    .course-icon {
        font-size: 1.6rem;
        color: #9d9fa9;
        transition: ease 0.3s;
        &.active {
            transform: rotate(180deg);
        }
    }
}
.lesson-body {
    display: none;
    animation: 200ms ease-out 0s 1 normal forwards running fadeIn;
}
.lesson-body.show {
    display: block;
}
.util {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: fadeMore ease 0.4s;
}
.util.show {
    display: flex;
}
.more {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
}
.create-lesson-update,
.create-lesson-delete {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px;
    transition: ease 0.2s;
    &:hover {
        opacity: 0.7;
    }
}
.create-lesson-update {
    background-color: blue;
}
.create-lesson-delete {
    background-color: red;
}

.alert-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    .alert-desc {
        font-size: 1.6rem;
        font-weight: 500;
    }
}
.custom-modal-title {
    font-size: 1.8rem;
    color: #ff0000;
    font-weight: bold;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeMore {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}
