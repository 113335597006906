.note-design {
    border: none !important;
    background-color: #323c4a !important;
    .jodit-status-bar {
        display: none;
    }

    // .jodit-ui-group_line_true + .jodit-ui-group_line_true {
    //     display: none;
    // }
    .jodit-add-new-line {
        display: none;
    }
}
