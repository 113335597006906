@use '../../../components/GlobalStyles/abstracts/' as abs;
.navbar {
    width: 23%;
    position: fixed;
    top: 50px;
    bottom: 50px;
    right: 0;
    overflow-y: auto;
    background-color: #282933;
    color: #fff;
    transition: ease 0.4s;
    transform: translateX(100%);
    opacity: 0;
    visibility: hidden;
    z-index: 999;
    @include abs.screen(lg) {
        width: 300px;
    }
    @include abs.screen(md) {
        width: 100%;
    }
    .heading {
        padding: 20px 10px;
        text-align: center;
        font-size: 1.8rem;
        font-weight: 500;
    }
    .heading {
        padding: 20px 10px;
        text-align: center;
        font-size: 1.8rem;
        font-weight: 500;
    }
}
.btn-wrap {
    display: flex;
    justify-content: flex-end;
}
.head-wrap {
    padding: 10px 20px;
    justify-content: center;
    @include abs.screen(md) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.x-mark {
    color: #fff;
    display: none;
    @include abs.screen(md) {
        display: inline-block;
    }
}
.title {
    font-size: 1.8rem;
    color: #fff;
    text-align: center;
    margin: 0;
}
.course-wrap {
    border-bottom: 2px solid #ccc;
}
.navbar.show {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
}
.course-wrap {
    .course {
        padding: 10px;
        display: flex;
        align-items: center;

        flex-wrap: wrap;
        justify-content: space-between;
        border-bottom: 1px solid #000;
        gap: 10px;
    }
    .course-header {
        display: flex;
        flex-direction: column;
        gap: 4px;
        flex: 1;
        .title {
            display: flex;
            flex: 1;
            align-items: center;
            gap: 10px;
            font-size: 1.8rem;
            font-weight: 500;
        }
        .quantity {
            font-size: 1.4rem;
            font-weight: 500;
            color: #9d9fa9;
        }
    }
    .course-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: #33343c;
        border-radius: 10px;
        cursor: pointer;
        &:hover {
            opacity: 0.7;
        }
    }
}

.course-input {
    background-color: transparent;
    color: #fff;
    border: 1px solid #454444;
    padding: 4px;
    border-radius: 4px;
    font-size: 1.8rem;
    font-weight: 500;
    transition: ease 0.4s;
    flex: 1;
    &:focus {
        border: 1px solid #ccc;
    }
}
.create-course-wrap,
.save-course-wrap {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.save-course-wrap {
    margin-top: 20px;
    &.block {
        opacity: 0.7;
        cursor: not-allowed;
        .save-course {
            opacity: 0.7;
            cursor: not-allowed;
        }
    }
}
.create-course,
.save-course {
    display: block;
    width: 100%;
    padding: 10px;
    color: #fff;
    border-radius: 16px;
    margin-right: 10px;
    margin-bottom: 10px;

    &:hover {
        opacity: 0.7;
    }
}
