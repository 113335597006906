.slider {
    width: 100%;
    .banner {
        width: 224px;
        height: 280px;
        overflow: hidden;
        border-radius: 16px;
        .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
