.wrap {
    padding: 10px;
    background-color: #fff;
}
.utils {
    display: flex;
    align-items: center;
    gap: 16px;
}
.title {
    margin: 10px 10px 20px 10px;
    color: #fff;
    font-size: 3rem;
    font-weight: 500;
}
.load-wrap {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
