.wrap {
    position: relative;
    padding: 16px;
    border-radius: 4px;
    transition: background-color 0.25s;
    background-color: transparent;
    &:hover {
        background-color: #32353b;
    }
    &.delete {
        opacity: 0.7;
        background-color: rgb(154, 4, 4);
    }
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .title {
            color: #52eeee;
            letter-spacing: 0.5px;
            font-weight: 600;
            font-size: 1.6rem;
            margin: 0px;
            cursor: pointer;
        }
        .utils {
            display: none;
            align-items: center;
            gap: 10px;
            transition: ease 0.3s;
        }
        .icon {
            cursor: pointer;
        }
    }
    .desc-wrap {
        word-wrap: break-word;
        cursor: pointer;
    }
}
.wrap:hover {
    .utils {
        display: flex;
    }
}
.over-lay {
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0;
    visibility: hidden;
    z-index: 10;
}
.over-lay.show {
    opacity: 0.7;
    visibility: visible;
}
