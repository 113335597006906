.teacher-class-design {
    width: 100%;
    height: 100%;
    .jodit-status-bar {
        display: none;
    }
    .jodit-add-new-line {
        display: none;
    }
}
