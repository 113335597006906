$grid-breakpoints: (
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);
@mixin screen($key) {
    $breakpoint: map-get($grid-breakpoints, $key);
    @if ($breakpoint) {
        @media (max-width: ($breakpoint - 0.02)) {
            @content;
        }
    } @else {
        @error "This value #{$key} is not availabel in breakpoint list";
    }
}
