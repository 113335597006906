.wrap {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.2s;
    &:hover {
        box-shadow: 0 4px 8px #0000001a;
        transform: translateY(-10px);
    }
    .banner {
        position: relative;
        display: block;
        width: 100%;
        padding-top: 56.25%;
        object-fit: cover;
        overflow: hidden;
        &:hover {
            .continue-wrap {
                background-color: rgba(0, 0, 0, 0.3);
                opacity: 1;
                visibility: visible;
            }
        }
        .continue-wrap {
            z-index: 1;
            position: absolute;
            margin: auto;
            opacity: 0;
            inset: 0;
            visibility: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            transition: ease 0.3s;
            .continue {
                background-color: #fff;
                padding: 12px 20px;
                border-radius: 999px;
                font-size: 1.6rem;
                font-weight: 600;
                color: #595555;
                cursor: pointer;
            }
        }
        .img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .info {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 16px 20px;
        background-color: #00000008;
        .title {
            color: #292929;
            font-size: 1.6rem;
            line-height: 1.4;
            overflow: hidden;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }
        .cost {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 18px;
            .price {
                font-size: 1.4rem;
                text-decoration: line-through;
            }
            .total {
                font-size: 1.6rem;
                font-weight: 600;
                color: var(--primary-color);
            }
        }
    }
    .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.process {
    position: relative;
    height: 8px;
    background-color: var(--bg-color, #ddd);
    border-radius: 4px;
    overflow: hidden;
}
