.wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -2;
        background: #fff;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        opacity: 0.08;
        background-image: radial-gradient(#ffffff40, #fff0 40%),
            radial-gradient(
                hsl(44, 100%, 66%) 30%,
                hsl(338, 68%, 65%),
                hsla(338, 68%, 65%, 0.4) 41%,
                transparent 52%
            ),
            radial-gradient(hsl(272, 100%, 60%) 37%, transparent 46%),
            linear-gradient(155deg, transparent 65%, hsl(142, 70%, 49%) 95%),
            linear-gradient(45deg, #0065e0, #0f8bff);
        background-size: 200% 200%, 285% 500%, 285% 500%, cover, cover;
        background-position: bottom left, 109% 68%, 109% 68%, center, center;
    }
}
