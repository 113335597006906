.wrap {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    display: none;
    &.active {
        display: block;
    }
}
.img-wrap {
    width: 100%;
    height: 300px;
    margin: 10px 0px;
    border: 2px dashed #000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.create-img {
    display: flex;
    align-items: center;
    gap: 10px;
}
.create-img-title {
    font-size: 1.6rem;
    font-weight: 500;
    cursor: pointer;
}
.img-up-wrap {
    position: relative;
    width: 100%;
    height: 100%;
}
.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.up-wrap {
    margin: 10px 0px;
}
.img-icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    top: -10px;
    right: -5px;
    z-index: 1;
    background-color: red;
    color: #fff;
    width: 20px;
    height: 20px;

    cursor: pointer;
}
.create-wrap {
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    padding: 10px 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: ease 0.3s;
    &:hover {
        background-color: #eeeaea;
    }
    .create-title {
        font-size: 1.6rem;
        font-weight: 500;
    }
}
