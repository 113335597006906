.wrap {
    width: 240px;
    height: 280px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .delete {
        position: absolute;
        top: 4px;
        right: 4px;
        border-radius: 50%;
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fdfcfc;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.4);
    }
}
