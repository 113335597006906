@use '../../components/GlobalStyles/abstracts/' as abs;
.content {
    margin-top: 66px;
    min-height: 100vh;
    .body {
        margin-top: 18px;
        max-width: 1100px;
        margin: 0 auto;
    }
    .nav-mobile {
        display: none;
        @include abs.screen(md) {
            display: block;
        }
    }
}
