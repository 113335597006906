@use '../../components/GlobalStyles/abstracts/' as abs;

.wrap {
    background-color: #f6f8fc;
}
.body {
    height: calc(100vh - 66px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    @include abs.screen(md) {
        padding: 0px 16px;
    }
    .icon {
        position: absolute;
        top: 20px;
        left: 20px;
        background-color: rgba(87, 84, 84, 0.3);
        padding: 12px;
        cursor: pointer;
        color: #060606;
        border-radius: 50%;
    }
}
