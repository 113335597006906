.wrap {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.2s;
    position: relative;
    &:hover.teacher {
        box-shadow: 0 4px 8px #0000001a;
        transform: translateY(0);
    }
    &:hover {
        box-shadow: 0 4px 8px #0000001a;
        transform: translateY(-10px);
    }
    .banner {
        position: relative;
        display: block;
        width: 100%;
        padding-top: 56.25%;
        object-fit: cover;
        overflow: hidden;
        .img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .info {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 16px 20px;
        background-color: #00000008;
        .title {
            color: #292929;
            font-size: 1.6rem;
            height: 20px;
            line-height: 1.4;
            overflow: hidden;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
        }
        .cost {
            display: flex;
            align-items: center;
            gap: 8px;
            .price {
                font-size: 1.4rem;
                text-decoration: line-through;
            }
            .total {
                font-size: 1.6rem;
                font-weight: 600;
                // color: var(--primary-color);
                color: #000;
            }
            .total.sale {
                color: var(--primary-color);
            }
        }
    }
    .private {
        font-size: 1.6rem;
        font-weight: 600;
        color: #4f4e4e;
    }
    .free {
        font-size: 1.6rem;
        font-weight: 600;
        color: var(--primary-color);
    }
    .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.info-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.info-type-wrap {
    display: flex;
    align-items: center;
    gap: 4px;
}
.info-type {
    font-size: 1.2rem;
}
.info-img {
    width: 30px;
    height: 30px;
    object-fit: cover;
}
.alert-app {
    top: 0;
    left: 0;
    right: 0;
    padding: 4px 10px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: yellow;
    color: #5e5959;
    .alert-icon {
        cursor: pointer;
    }
}
