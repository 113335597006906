@use '../../../components/GlobalStyles/abstracts/' as abs;
.navbar {
    position: fixed;
    left: 0;
    padding: 0px 8px;
    margin-top: 18px;
    height: 100%;
    @include abs.screen(md) {
        position: fixed;
        top: unset;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 70px;
        padding: 0;
        background-color: #fff;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
        margin-bottom: 0;
    }
    .list {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 10px;
        padding-left: 0;
        @include abs.screen(md) {
            flex-direction: row;
            justify-content: space-around;
        }
        .item {
            row-gap: 4px;
            transition: ease 0.3s;
            border-radius: 16px;
            color: #404040;
            &:hover {
                background-color: #f4f7f8;
            }
        }
        .item-sub {
            width: 72px;
            height: 72px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .item.active {
            background-color: #e8ebed;
            color: #1a1a1a;
            @include abs.screen(md) {
                background-color: #fff;
                color: #f05123;
            }
        }
        .desc {
            font-size: 1.1rem;
            font-weight: 600;
            margin-top: 8px;
        }
    }
}
.alert {
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    bottom: 140px;
    left: 50%;
    transform: translateX(-50%);
    background: #f0f0f0cc;
    border: 1px solid #ccc;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    @include abs.screen(md) {
        display: none;
    }
}
