.wrap {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
        text-align: center;
        font-size: 16rem;
        font-weight: 900;
        background-color: #0093e9;
        background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .title-sub {
        display: block;
        text-align: center;
        font-size: 5rem;
        font-weight: 900;
    }
    .desc {
        font-size: 1.6rem;
        font-weight: 500;
        margin: 20px 0px;
        width: min(600px, 100%);
        padding: 0px 10px;
        text-align: center;
        word-wrap: break-word;
    }
}
.btn {
    color: #fff !important;
}
