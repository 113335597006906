.wrap {
    .heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 2px;
        border-bottom: 1px solid #ccc;
        padding-top: 10px;
        .title {
            margin-bottom: 0;
        }
        .btn {
            font-size: 1.4rem;
            font-weight: 400;
            color: var(--gray-color);
            transition: ease 0.4s;
            &:hover {
                color: var(--primary-color);
            }
        }
    }
}
