@use '../../components/GlobalStyles/abstracts/' as abs;
.wrap {
    margin-top: 24px;
    padding: 0 44px 68px;
    @include abs.screen(sm) {
        padding: 0px 10px 68px;
    }
}
.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.banner {
    width: 100%;
    height: 200px;
    border-radius: 16px;
    object-fit: cover;
}
.costs {
    font-size: 32px;
    font-weight: 400;
    color: var(--primary-color);
    opacity: 0.8;
}
.list {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 0;
    @include abs.screen(lg) {
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        gap: 10px;
        margin-bottom: 50px;
    }
    .item {
        display: flex;
        align-items: center;
        gap: 16px;
        line-height: 1.6;
        font-size: 1.4rem;
        color: #494949;
    }
}

.design {
    min-height: 50vh;
    @include abs.screen(sm) {
        margin-top: 30px;
    }
}
.user {
    display: flex;
    align-items: center;
    gap: 10px;
    .avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
    }
    .name {
        font-size: 1.6rem;
        font-weight: 500;
    }
}
.info-bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-left: 2px solid #ccc;
    padding-left: 10px;
    .list {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;
    }
}
.item-sub {
    display: flex;
    align-items: center;
    gap: 10px;
    .item-info {
        display: block;
        line-height: 20px;
        font-size: 1.5rem;
        font-weight: 500;
    }
}
