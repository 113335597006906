.wrap {
    width: 100%;
    padding: 10px 0px;
    border-bottom: 1px solid #ccc;
    .head {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        .avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            flex-shrink: 0;
        }
    }
}
