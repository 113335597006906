@use '../../../../components/GlobalStyles/abstracts/' as abs;

.side-bar {
    background-color: #282933;
    color: white;
    height: 100vh;
    @include abs.screen(md) {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        width: 50%;
        transition: ease 0.4s;
        transform: translateX(-100%);
        z-index: 99;
        &.active {
            transform: translateX(0);
        }
    }
    @include abs.screen(sm) {
        width: 80%;
    }
}
.info-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    @include abs.screen(lg) {
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
    }
    .user-wrap {
        display: flex;
        align-items: center;
        gap: 4px;
        @include abs.screen(md) {
            margin: 10px 0px;
        }
        .user-name {
            color: #fff;
            font-size: 1.8rem;
            font-weight: 400;
        }
    }
}
.back-btn {
    @include abs.screen(md) {
        flex: 1;
    }
}
.wrap-btn {
    @include abs.screen(md) {
        width: 100%;
    }
}
.x-icon {
    display: none;
    background-color: transparent;
    color: #fff;
    padding: 0px 10px;
    @include abs.screen(md) {
        display: block;
    }
}
.room-list {
    color: #fff !important;
    display: flex;
    flex-direction: column;
    .room-item {
        display: block;
        padding: 6px;
        font-size: 1.6rem;
        font-weight: 500;
        transition: ease 0.3s;
        color: #ccc;
        &:hover {
            background-color: #3f4047;
        }
    }
}
.info-wrap-nav {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
}
.invite-head {
    display: none;
    width: 100%;
    padding: 10px 20px;
    margin-top: 20px;
    @include abs.screen(md) {
        display: block;
    }
}
.over-lay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 98;
    display: none;
    &.active {
        display: block;
    }
}
.empty {
    display: block;
    font-size: 1.4rem;
    font-weight: 400;
    color: #ccc;
    text-align: center;
}
