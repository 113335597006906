.input {
    width: 100%;
    border: none;
    font-size: 3.2rem;
    font-weight: 500;
    min-height: 48px;
    color: #292929;
}
.content {
    margin-bottom: 31px;
    height: calc(-100px + 100vh);
}
.design {
    width: 100%;
    height: 100%;
}
.output {
    padding: 10px;
}
.input-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 20px 0px;
    margin-bottom: 20px;
}
.body-title {
    height: 39px;
    padding-left: 20px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.8rem;
    color: #5f5c5c;
    border-bottom: 1px solid #ccc;
}
.btn-export {
    background: #0093fc;
}
.upload-wrap {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px dashed #ccc;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
        border: 1.5px dashed #000;
        .upload-none {
            color: #000;
        }
    }
    .upload-none {
        cursor: pointer;
        color: #6b6969;
        display: flex;
        align-items: center;
        gap: 10px;
        .upload-title {
            font-size: 2rem;
            font-weight: 500;
        }
    }
}
.banner {
    width: 100%;
    height: 300px;
    object-fit: cover;
}
