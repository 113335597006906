@use '../../../../../components/GlobalStyles/abstracts/' as abs;

.nav-wrap {
    background-color: transparent;
    width: 100%;
    padding: 10px;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 999;
    display: none;
    @include abs.screen(md) {
        display: block;
    }
    .navbar {
        display: flex;
        align-items: center;
        height: 100%;
    }
    .list {
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        flex: 1;
        gap: 10px;
        .item {
            padding: 0;
            margin: 0;
            width: 100%;
            height: 40px;
            background-color: transparent;
            display: flex;
            justify-content: center;
            text-align: center;
            cursor: pointer;
            transition: ease 0.3s;
            border-radius: 8px;
            &:hover {
                background-color: #ccc;
            }
            &.active {
                background-color: #ccc;
            }
        }
        .icon {
            width: 30px;
            height: 30px;
            display: flex;
            align-self: center;
            justify-self: center;
        }
    }
}
.out-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #e3e2e2;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
        background-color: #ccc;
    }
}
