@use '../../../components/GlobalStyles/abstracts/' as abs;
.admin {
    padding: 20px;
    width: 100%;
    margin: 0 auto;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    .logo-name {
        font-size: 3rem;
        font-weight: 500;
        color: #f0eded;
    }
}
.user-wrap {
    display: flex;
    align-items: center;
    gap: 20px;
    .icon {
        color: #fff;
        cursor: pointer;
    }
    @include abs.screen(sm) {
        gap: 10px;
    }
}

.alert-item {
    font-size: 1.6rem;
    padding: 10px;
}

.alert-dropdown {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    height: 300px;
    width: min(300px, 100%);
    overflow-y: auto;
    .head {
        font-size: 2rem;
        font-weight: 500;
        color: #3f3f3f;
        text-align: center;
        border-bottom: 1px solid #3f3f3f;
        padding: 10px;
    }
    .wrap {
        border-bottom: 1px solid #3f3f3f;
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .title {
            font-size: 1.6rem;
            font-weight: 500;
            color: teal;
        }
        .desc {
            font-size: 1.6rem;
            font-weight: 400;
        }
    }
}
.avatar {
    background-color: #292d35;
    border-radius: 999px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 2px 6px;
    .avatar-img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        object-fit: cover;
    }
    .info {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #fff;
        .info-name {
            margin: 0;
        }
        .icon-info {
            display: inline-block;
            font-size: 1.2rem;
        }
    }
}
