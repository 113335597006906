.course {
    border: 1px solid #ccc;
    transition: ease 0.4s;
    padding: 10px;
    margin: 10px 0px;
}
.arrow {
    transition: ease 0.4s;
}
.wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .name {
            font-size: 2rem;
            font-weight: 500;
        }
        .lesson {
            font-size: 1.4rem;
            font-weight: 400;
            color: #4e4e4e;
        }
    }
}
