@use '../../../../../components/GlobalStyles/abstracts/' as abs;
.wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 640px;
    margin: 0 auto;
    min-height: 100vh;
    border: 1px solid #000;
    margin-top: 10px;
    padding: 10px;
    border-radius: 20px;
    @include abs.screen(md) {
        width: 90%;
        margin-bottom: 100px;
    }
}
.header {
    width: 100%;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    gap: 20px;
    border-bottom: 1px solid #000;
    padding-bottom: 20px;
}
.body {
    width: 100%;
    overflow: hidden;
}
.info-user {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .name {
        font-size: 1.8rem;
        font-weight: 500;
    }
}
.sub {
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-top: 20px;
    font-size: 1.4rem;
    font-weight: 400;
    color: #646262;
}
