.wrap {
    width: 100%;
    min-height: calc(100vh - 110px);
    margin-bottom: 50px;
}
.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.btn-wrap {
    background-color: #678cff;
    padding: 10px 28px;
    margin-left: auto;
    border-radius: 999px;
    font-size: 1.6rem;
    font-weight: 500;
    color: #fff;
    margin-bottom: 10px;
}
