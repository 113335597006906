.wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 6px 0;
    .avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        overflow: hidden;
    }
}
