.modal {
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    &.active {
        opacity: 1;
        visibility: visible;
    }
    .content {
        position: absolute;
        width: min(900px, 90%);
        height: calc(100vh - 200px);
        background-color: #181c23;
        overflow: hidden auto;
        z-index: 2;
        border-radius: 10px;
        .back {
            position: absolute;
            top: 0;
            right: 0;
            color: #ccc;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover {
                color: #fff;
            }
        }
        .body {
            width: 100%;
            height: 100%;
            padding: 20px;
            .title {
                font-size: 2rem;
                font-weight: 500;
                color: #52eeee;
                margin-bottom: 10px;
                display: block;
            }
            .text {
                color: #fff;
                font-size: 1.6rem;
                font-weight: 400;
            }
        }
    }
    .over-lay {
        position: absolute;
        z-index: 1;
        inset: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
}
