.wrap {
    padding: 0px 20px 20px 20px;
    .head {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 40px;
        .title {
            font-size: 3rem;
            font-weight: 500;
            color: #fff;
        }
    }
}
