@use '../../../components/GlobalStyles/abstracts/' as abs;
.banner-wrap {
    height: 270px;
    border-radius: 16px;
    overflow: hidden;
    cursor: grab;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    &.st-0 {
        background-color: #0093e9;
        background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
    }
    &.st-1 {
        background-color: #4158d0;
        background-image: linear-gradient(76deg, #4158d0 0%, #c850c0 46%, #ffcc70 100%);
    }
    &.st-2 {
        background-color: #8bc6ec;
        background-image: linear-gradient(135deg, #8bc6ec 0%, #9599e2 100%);
    }
    &.st-3 {
        background-image: radial-gradient(
            circle farthest-corner at 0.2% 0.5%,
            rgba(68, 36, 164, 1) 3.7%,
            rgba(84, 212, 228, 1) 92.7%
        );
    }
    .st-left {
        width: 640px;
    }
    .st-right {
        text-align: end;
        width: 500px;
        height: 100%;
        @include abs.screen(lg) {
            display: none;
        }
    }
    .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .title {
        color: #fff;
        font-size: 3.6rem;
        line-height: 1.5;
        font-weight: 700;
        @include abs.screen(md) {
            font-size: 3rem;
        }
        @include abs.screen(sm) {
            font-size: 2rem;
        }
    }
    .desc {
        font-size: 1.6rem;
        font-weight: 400;
        color: #fff;
        width: 600px;
        line-height: 2.6rem;
        @include abs.screen(md) {
            width: 100%;
            line-height: 2rem;
        }
    }
    .img-sub {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
