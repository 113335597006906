@use '../../../../components/GlobalStyles/abstracts/' as abs;
.wrap {
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        padding: 4px 10px;
        .head-info {
            display: flex;
            flex-direction: column;
            gap: 8px;
            .head-title {
                font-size: 2rem;
                font-weight: 500;
                color: #000;
            }
            .head-sub {
                font-size: 1.6rem;
                font-weight: 400;
            }
        }
        .invite-wrap {
            display: flex;
            align-items: center;
            gap: 20px;
            cursor: pointer;
        }
        .invite-head {
            @include abs.screen(md) {
                display: none;
            }
        }
    }
    .body {
        height: calc(100vh - 70px);
        display: flex;
        flex-direction: column;
        justify-content: end;
        padding: 10px;
        .send-wrap {
            display: flex;
            align-items: center;
            border: 1px solid #ccc;
            &:focus-within {
                border: 1px solid #000;
            }
            .send-input-wrap {
                flex: 1;
                height: 100%;
                margin-bottom: 0px;
            }
            .send-submit {
                height: 100%;
                padding: 4px 15px;
                font-size: 1.6rem;
                font-weight: 400;
                background-color: rgb(77, 160, 228);
                color: #fff;
                opacity: 0.5;
                cursor: default;
                &.allow {
                    opacity: 1;
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}
.avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}
.messages {
    // height: calc(100vh - 122px);
    max-height: 100%;
    overflow-y: auto;
    left: 0;
    right: 0;
    bottom: 50px;
    padding: 0px 10px;
}
.message {
    margin: 10px 0px;
    .message-info {
        display: flex;
        align-items: center;
        gap: 10px;
        .message-info-head {
            display: flex;
            align-items: center;
            gap: 10px;
            .user-name {
                font-size: 1.8rem;
                font-weight: 500;
            }
            .timmer {
                font-size: 1.4rem;
                font-weight: 400;
                color: #696868;
            }
        }
    }
    .content {
        margin-left: 42px;
        margin-top: 4px;
        font-size: 1.6rem;
        font-weight: 400;
    }
}
.alert {
    flex: 1;
}
.bar-btn,
.bar-btn-sub {
    padding-right: 10px;
    background-color: transparent;
    display: none;
    @include abs.screen(md) {
        display: block;
    }
}
.bar-btn-sub {
    padding-left: 10px;
}
