@use '../../../components/GlobalStyles/abstracts/' as abs;

.wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 200;
    height: 50px;
    padding: 0px 25px;
    background: #191d1e;
    -webkit-backdrop-filter: blur(32px);
    backdrop-filter: blur(32px);
    @include abs.screen(sm) {
        padding: 0px 10px;
    }
    .right {
        display: flex;
        align-items: center;
        gap: 20px;
    }
}

.left {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 100%;
    @include abs.screen(sm) {
        gap: 20px;
    }
    .icon-back {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 20px;
        height: 100%;
        cursor: pointer;
        color: #fff;
        transition: background-color 0.3s ease, opacity 0.3s ease;
        @include abs.screen(sm) {
            padding: 0px 10px;
        }
        &:hover {
            background-color: #4b4b4b;
            opacity: 0.7;
        }
    }
}
