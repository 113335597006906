.wrap {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10001;
    opacity: 0;
    visibility: hidden;
    .content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 10px;
        padding: 20px;
        max-height: calc(100% - 32px);
        overflow: hidden;
        background-color: #272a31;
        .header-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
        }
        .title {
            font-size: 1.8rem;
            font-weight: 600;
            color: #fff;
        }
        .x-mark {
            font-size: 2rem;
            color: #fff;
            cursor: pointer;
        }
    }
}
.over-lay {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    inset: 0;
    background-color: #000;
    z-index: 10000;
}
.wrap.show {
    opacity: 1;
    visibility: visible;
    animation: scale ease 0.3s;
    width: 100vw;
    height: 100vh;
}
.over-lay.show {
    opacity: 0.7;
    visibility: visible;
    width: 100vw;
    height: 100vh;
}
.input-title {
    background-color: transparent;
    font-size: 2rem;
    font-weight: 400;
    padding: 10px 0px;
    color: #fff;
    width: 100%;
    &::placeholder {
        color: #ccc;
    }
}
@keyframes scale {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
