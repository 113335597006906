@use '../../components/GlobalStyles/abstracts/' as abs;
.wrap {
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.cart-wrap {
    margin: 0 auto;
    width: 358px;
    height: auto;
    @include abs.screen(sm) {
        width: 100%;
    }
}
.desc {
    word-break: break-all;
}
