@use '../../components/GlobalStyles/abstracts/' as abs;

.zoom {
    position: fixed;
    z-index: 999999;
    inset: 0;
    width: 100vw;
    height: 100vh;
    display: none;
    overflow: hidden;
    animation: fadeIn ease 0.1s;
    &.active {
        display: block;
    }
    .banner {
        z-index: 10;
        position: absolute;
        width: 500px;
        height: 100vh;
        left: 50%;
        transform: translateX(-50%);
        @include abs.screen(sm) {
            width: min(360px, 100vw);
            height: 470px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .overlay {
        z-index: 9;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #000;
        overflow: hidden;
    }
    .x-mart {
        z-index: 10;
        cursor: pointer;
        position: absolute;
        left: 40px;
        top: 20px;
        color: #888383;
        border-radius: 50%;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        background-color: rgb(41, 40, 40);
        @include abs.screen(sm) {
            left: 10px;
            top: 10px;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.95);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
