@use '../../../components/GlobalStyles/abstracts/' as abs;
.wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #5b5959;
}
.icon {
    font-size: 3rem;
}
.title {
    font-size: 3rem;
    font-weight: 500;
    @include abs.screen(sm) {
        font-size: 2rem;
    }
}
.wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
