.wrap {
    width: 100%;
    min-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.utils {
    display: flex;
    align-items: center;
    gap: 10px;
}
.load-wrap {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
