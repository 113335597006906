@use '../../../../components/GlobalStyles/abstracts/' as abs;
.private-wrap {
    .list {
        display: flex;
        align-items: center;
        gap: 20px;
        margin: 0;
        padding: 0;
    }
    .item {
        font-size: 1.6rem;
        font-weight: 500;
        padding: 8px;
        color: #464545;
        background-color: transparent;
    }
    .avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        .avatar-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .icon {
        color: #706e6e;
        &:hover {
            color: #000;
        }
    }
}
.class-title {
    font-size: 1.6rem;
    font-weight: 500;
    cursor: pointer;
    padding: 8px;
    color: #333;
    background-color: transparent;
    margin-bottom: 0;
    color: var(--primary-color);
}
.item-classes {
    @include abs.screen(lg) {
        display: none;
    }
}
.class-drop {
    background: var(--white-color);
    box-shadow: 0 -4px 32px #0003;
    transition: all ease-in-out 0.3s;
    animation: fadeIn ease 0.3s;
    border-radius: 10px;
    padding-left: 0;
    margin: 0;
    overflow: hidden;
    will-change: opacity, transform;
    color: #1d2129;
    .class-head {
        padding: 14px 20px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .class-btn {
            color: var(--invalid-color);
            display: inline-block;
            padding: 10px 0px;
            font-size: 1.4rem;
            font-weight: 400;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .class-body {
        max-height: 68vh;
        overflow-y: auto;
        overscroll-behavior: contain;
    }
}

.icon {
    cursor: pointer;
}

.alert-body {
    padding: 0px 10px;
    max-height: 68vh;
    overflow-y: auto;
    overscroll-behavior: contain;
}

// Profile
.profile-drop {
    background: var(--white-color);
    padding: 20px;
    box-shadow: 0 -4px 32px #0003;
    transition: all ease-in-out 0.3s;
    animation: _fadeIn_qhx2q_1 ease 0.3s;
    border-radius: 10px;
    margin: 0;
    overflow: hidden;
    will-change: opacity, transform;
    color: #1d2129;
    transition: all ease-in-out 0.3s;
    animation: fadeIn ease 0.3s;
    .profile-info {
        display: flex;
        align-items: center;
        gap: 16px;
        .profile-avatar {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 50%;
        }
        .profile-user {
            .profile-name {
                font-size: 1.6rem;
                font-weight: 600;
                color: #292929;
            }
            .profile-email {
                margin-top: 8px;
                font-size: 1.4rem;
                color: #757575;
            }
        }
    }
    .profile-list {
        padding-left: 0;
        .profile-item-link {
            font-size: 1.6rem;
            font-weight: 400;
            color: #666;
            display: block;
            padding: 10px 0;
            cursor: pointer;
            &:hover {
                color: #000;
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-8px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
