@use '../../components/GlobalStyles/abstracts/' as abs;

.wrap {
    width: 640px;
    margin: 0 auto;
    background-color: transparent;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #000;
    padding: 10px;
    margin-top: 10px;
    border-radius: 16px;
    padding-bottom: 20px;
    @include abs.screen(md) {
        width: 90%;
    }
    .back {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 1px solid #000;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .header {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        margin-bottom: 10px;
        .title {
            margin-bottom: 0px;
        }
        .timer {
            font-size: 1.4rem;
            font-weight: 400;
            color: #464444;
        }
    }
    .desc {
        font-size: 1.6rem;
        font-weight: 400;
        word-break: break-all;
        margin-bottom: 10px;
    }
}
.option {
    margin-left: auto;
    padding: 10px 6px 10px 10px;
    cursor: pointer;
    text-align: end;
}
.drop-item {
    color: red;
    font-size: 1.4rem;
    width: 100%;
    cursor: pointer;
    padding: 10px;
    transition: ease 0.4s;
    &:hover {
        background-color: #f0ecec;
    }
}
.dropdown {
    background-color: #fff;
    border: 1.5px solid #ccc;
    border-radius: 20px;
    padding: 10px 0px;
    overflow: hidden;
}

.footer {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 0px 0px 10px;
    .footer-wrap {
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
        user-select: none;
    }
}

.head-load {
    width: 100%;
    margin: 20px 0px;
    display: flex;
    align-items: center;
    gap: 10px;
    .avatar-load {
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
    }
}
.content-load,
.comment-load {
    width: 100%;
}
