.wrap {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #666;
    font-size: 1.4rem;
    .img {
        border-radius: 50%;
        width: 18px;
        height: 18px;
        object-fit: cover;
        &.large {
            width: 26px;
            height: 26px;
        }
    }
}
.wrap-body {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
