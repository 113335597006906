@use '../../components/GlobalStyles/abstracts/' as abs;
.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    @include abs.screen(md) {
        flex-direction: column-reverse;
    }
    .list {
        display: flex;
        align-items: center;
        gap: 20px;
        margin: 0;
        padding: 0;
        @include abs.screen(md) {
            width: 100%;
            justify-content: space-between;
        }
        .item {
            position: relative;
            cursor: pointer;
            font-size: 1.8rem;
            font-weight: 500;
            padding: 14px;
            color: #8c8989;
            @include abs.screen(lg) {
                font-size: 1.6rem;
                font-weight: 400;
            }
            @include abs.screen(sm) {
                font-size: 1.4rem;
            }
            &::after {
                content: '';
                position: absolute;
                transition: ease 0.4s;
                bottom: 0;
                left: 0;
                right: 0;
                height: 1px;
                width: 0;
                background-color: transparent;
            }
            &.active {
                color: #000;
                &::after {
                    width: 100%;
                    background-color: #000;
                }
            }
        }
    }
    .options {
        width: 200px;
        @include abs.screen(md) {
            width: 250px;
        }
        @include abs.screen(sm) {
            width: 180px;
        }
    }
}
.body {
    border-top: 1px solid #ccc;
    padding: 20px 10px;
    .wrap-item {
        width: 100%;
        min-height: 688px;
        @include abs.screen(md) {
            min-height: auto;
        }
    }
}
.filter {
    width: 120px;
    @include abs.screen(md) {
        width: 250px;
    }
    @include abs.screen(sm) {
        width: 180px;
    }
}
.filter-wrap {
    display: flex;
    align-items: center;
}
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
}
