@use '../../components/GlobalStyles/abstracts/' as abs;

.logo {
    display: flex;
    align-items: center;
    gap: 16px;
    .logo-desc {
        font-size: 1.6rem;
        font-weight: 700;
        color: #000;
        @include abs.screen(lg) {
            display: none;
        }
    }
    .logo-desc.white,
    .logo-icon.white {
        color: #fff;
    }
}
