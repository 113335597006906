@use '../../components/GlobalStyles/abstracts/' as abs;
.wrap {
    height: 100vh;
    .body {
        margin-top: 50px;
        min-height: calc(100vh - 50px);
        display: flex;
        padding: 20px;
        @include abs.screen(sm) {
            padding: 0;
        }
    }
    .content {
        width: 77%;
        margin-bottom: 50px;
        @include abs.screen(lg) {
            margin-left: 0;
            width: 100%;
        }
    }
    .content.full {
        width: 100%;
    }
}
