@use '../../components/GlobalStyles/abstracts/' as abs;
.content {
    margin-top: 50px;
}
.banner {
    // border-radius: 16px;
    // overflow: hidden;
}
.title-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 50px;
    .title {
        font-size: 3rem;
        font-weight: 500;
    }
}
.icon-wrap {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.empty-cart {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 315px;
    transition: ease 0.2s;
    cursor: pointer;
    border: 2px dashed #ccc;
    border-radius: 16px;
    @include abs.screen(sm) {
        width: 100%;
        height: 200px;
    }
    &:hover {
        border: 2px dashed #000;
    }
}
.empty-class {
    display: block;
    text-align: center;
    color: #827f7f;
}
.info {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 2rem;
    color: #ccc;
    transition: ease 0.4s;
}

.empty-cart:hover {
    .info {
        color: #000;
    }
}
.class-wrap {
    max-height: calc(100vh - 300px);
    overflow: hidden auto;
}
