@use '../../../components/GlobalStyles/abstracts/' as abs;

.header {
    padding: 0px 28px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 28px;
    border-bottom: 1px solid #e8ebed;
    background-color: #fff;
    font-size: 1.4rem;
    height: 66px;
    .btn-wrap {
        display: flex;
        align-items: center;
        grid-area: 16px;
    }
    @include abs.screen(lg) {
        gap: 20px;
    }
    @include abs.screen(sm) {
        padding: 0px 10px;
    }
}
.wrap {
    display: flex;
    align-items: center;
    gap: 20px;
}
.back-wrap {
    .icon {
        display: flex;
        align-items: center;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.2rem;
        color: #808990;
    }
    .back-desc {
        margin-left: 4px;
    }
    .font-icon {
        display: inline-block;
        transition: ease 0.4s;
    }
    &:hover {
        .font-icon {
            transform: translateX(-4px);
        }
    }
}
.register {
    @include abs.screen(sm) {
        display: none !important;
    }
}
.logo {
    @include abs.screen(lg) {
        display: none;
    }
}
.back-wrap {
    display: none;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    font-size: 1.6rem;
    &.active {
        display: flex;
    }
    .back-icon {
        transition: ease 0.2s;
    }
    &:hover {
        .back-icon {
            transform: translateX(-3px);
        }
    }
}
