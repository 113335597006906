.wrap {
    .title {
        margin: 32px 0 20px;
        font-size: 2.4rem;
        font-weight: 900;
        line-height: 1.4;
        color: #242424;
    }
    .desc {
        color: #292929;
        word-wrap: break-word;
        line-height: var(--line-height, 2);
        font-size: 1.6rem;
        margin-bottom: 80px;
    }
    .mark {
        font-weight: 700;
        color: #000;
    }
}
