.sub-btn {
    color: #fff !important;
    opacity: 0.4;
    cursor: not-allowed !important;
    &:hover {
        opacity: 0.4;
    }
}
.public-btn {
    color: #fff !important;
}
.private-join-btn {
    color: #fff;
}
.sub-btn.active {
    opacity: 1;
    cursor: pointer !important;
    &:hover {
        opacity: 1;
    }
}
.unsub {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    min-width: 100px;
    background-color: transparent;
    padding: 9px 20px;
    font-size: 1.6rem;
    font-weight: 500;
    border-radius: 9999px;
    cursor: pointer;
    gap: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0.7;
    color: #f1f1f1;
    &:hover {
        background-color: #313131;
    }
}
.public-btn-un-sub {
    padding: 20px;
}
.public-btn-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
}

.private-input {
    border: 1px solid #ccc;
}
.private-head {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    text-align: center;
    margin: 10px 0;
}
.public-head {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .price {
        font-size: 2rem;
        font-weight: 500;
        text-decoration: line-through;
        color: #787777;
    }
    .discount {
        background-color: #3eba0433;
        color: #67b044;
        padding: 5px 15px;
    }
}
.total {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    font-weight: 500;
    margin: 10px 0px;
}
.total.sale {
    color: var(--invalid-color);
}
.public-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.private-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.error {
    display: block;
    color: red;
    margin: 6px 0px;
    font-size: 1.4rem;
    animation: fadeIn ease 0.4s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-8px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
