@use '../../components/GlobalStyles/abstracts/' as abs;
.cart-wrap {
    margin-top: 50px;
    .cart-title {
        margin-bottom: 20px;
    }
    .cart-info {
        width: 50%;
        @include abs.screen(md) {
            width: 100%;
        }
    }
    .desc {
        margin-top: 50px;
    }
}
.head-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include abs.screen(sm) {
        margin-bottom: 50px;
    }
}
// Analyst Item
.ana-item {
    width: 100%;
    height: 100%;
    padding: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.75);
    border-left: 4px solid green;
    .ana-head {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
        color: #323232;
        .title {
            margin: 0;
            padding: 0;
            font-size: 2rem;
            font-weight: 500;
        }
    }
    .ana-desc {
        .ana-total {
            font-size: 1.8rem;
            font-weight: 500;
        }
        .ana-main {
            font-size: 3rem;
            font-weight: 500;
        }
    }
}
