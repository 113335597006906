.wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    padding-left: 16px;
    margin-top: 12px;
    border: 2px solid #dce0e3;
    border-radius: 44px;
    background-color: #fff;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s;
    &:hover {
        background-color: #dce0e3;
    }
    .icon {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
    }
    .desc {
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 40px;
    }
}
