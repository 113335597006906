.post {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    border-bottom: 1px solid #ccc;
    margin-top: 10px;
}
.body {
    overflow: hidden;
    .head-name {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
        .name {
            font-size: 1.8rem;
            font-weight: 500;
            margin-bottom: 0;
        }
        .timer {
            font-size: 1.4rem;
            font-weight: 400;
            color: #4a4747;
        }
    }
    .content {
        word-break: break-all;
        font-size: 1.6rem;
        font-weight: 400;
        margin-bottom: 20px;
    }
    .footer {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 10px 0px;
        .footer-wrap {
            display: flex;
            align-items: center;
            gap: 4px;
            cursor: pointer;
        }
    }
}
.footer-wrap {
    user-select: none;
}
.slider {
    width: 100%;
}
.banner {
    width: 224px;
    height: 280px;
    overflow: hidden;
    border-radius: 16px;
    .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.option {
    margin-left: auto;
    padding: 10px 6px 10px 10px;
    cursor: pointer;
}
.drop-item {
    color: red;
    font-size: 1.4rem;
    width: 100%;
    cursor: pointer;
    padding: 10px;
    transition: ease 0.4s;
    &:hover {
        background-color: #f0ecec;
    }
}
.dropdown {
    background-color: #fff;
    border: 1.5px solid #ccc;
    border-radius: 20px;
    padding: 10px 0px;
    overflow: hidden;
}
