.wrap {
}
.bg-video {
    background-color: #000;
    margin-top: 20px;
    overflow: hidden;
    .video-wrap {
        position: relative;
        width: 85%;
        max-width: min(100vh * (16 / 9) - (70px + 50px) * (16 / 9) - 100px, 1600px);
        margin: 0 auto;
    }
    .banner {
        display: flex;
        position: relative;
        padding-top: 56.25%;
        .video {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.delete-btn {
    background-color: red;
    color: #fff;
    margin: 10px 0px;
}
.desc {
    margin-top: 20px;
}

.btn-wrap {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}
