.wrap {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.2s;
    &:hover {
        box-shadow: 0 4px 8px #0000001a;
        transform: translateY(-10px);
    }
    .banner {
        position: relative;
        display: block;
        width: 100%;
        padding-top: 56.25%;
        object-fit: cover;
        overflow: hidden;
        .img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .info {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 16px 20px;
        background-color: #00000008;
        .title {
            color: #292929;
            font-size: 1.6rem;
            line-height: 1.4;
            height: 20px;
            overflow: hidden;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
        }
        .cost {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 18px;
            .price {
                font-size: 1.4rem;
                text-decoration: line-through;
            }
            .total {
                font-size: 1.6rem;
                font-weight: 600;
                color: var(--primary-color);
            }
        }
    }
    .footer {
        display: flex;
        align-items: center;
        gap: 6px;
    }
}
.desc {
    margin-top: 4px;
    font-size: 1.5rem;
    line-height: 1.6;
    color: #505050;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* Giới hạn hiển thị 3 dòng */
    overflow: hidden;
    text-overflow: ellipsis;
}
.info-avatar {
    border-radius: 50%;
    width: 18px;
    height: 18px;
    object-fit: cover;
}
.name {
    color: #666;
    font-size: 1.4rem;
}
