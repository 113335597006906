.create {
    transition: ease 0.4s;
    cursor: pointer;
    width: 100%;
    height: 400px;
    border: 2px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    gap: 10px;
    color: #6d6b6b;
    &:hover {
        border: 2px dashed #000;
        color: #000;
    }
}
.bg-video {
    background-color: #000;
    overflow: hidden;
    margin-top: 50px;
    .video-wrap {
        position: relative;
        width: 85%;
        max-width: min(100vh * (16 / 9) - (70px + 50px) * (16 / 9) - 100px, 1600px);
        margin: 0 auto;
    }
    .banner {
        display: flex;
        position: relative;
        padding-top: 56.25%;
        .video {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.video-wrap-all {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 10px;
    overflow: hidden;
}
