@use '../../components/GlobalStyles/abstracts/' as abs;
.wrap-all {
    min-width: 100vw;
    min-height: 100vh;
    background-color: #191d1e;
    color: #fdfdfd;
    position: relative;
}
.decor-1 {
    top: 0;
    left: 0;
}
.decor-2 {
    top: 0;
    right: 0;
}
.decor-2,
.decor-1 {
    position: absolute;
    height: 100vh;
    z-index: 1;
}
.wrap {
    position: relative;
    z-index: 2;
    z-index: 2;
    width: 1000px;
    margin: 0 auto;
    @include abs.screen(lg) {
        width: 100%;
    }
    .header {
        @include abs.screen(md) {
            margin-bottom: 10px;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
    }
}

.desc {
    font-size: 1.6rem;
    font-weight: 400;
    text-align: center;
    margin: 20px 0px 40px 0px;
    @include abs.screen(lg) {
        margin: 10px 0px;
    }
    @include abs.screen(md) {
        margin: 20px 10px;
        font-size: 1.4rem;
    }
}
.title-head {
    text-align: center;
    font-size: 5rem;
    font-weight: 600;
    padding-top: 20px;
    background: linear-gradient(90deg, #5ebbff, #a174ff);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.rank-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
    padding: 20px;
    @include abs.screen(md) {
        gap: 50px;
    }
    @include abs.screen(sm) {
        gap: 20px;
    }
    .img-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 120px;
        padding: 0.4em;
        position: relative;
        background: linear-gradient(180deg, #ffd900, #b45264 93.68%);
        border-radius: 50%;
        @include abs.screen(md) {
            width: 100px;
            height: 100px;
        }
        &.second {
            transform: translateY(10px);
        }
    }

    .img {
        width: 100%;
        height: 100%;
        object-fit: cover; /* Đảm bảo ảnh được bao phủ hoàn toàn */
        border-radius: 50%; /* Để giữ hình tròn cho ảnh */
    }
    .decor {
        position: absolute;
        top: -10px;
        width: 30px;
        height: 30px;
        right: -10px;
    }
}
.load {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}
.load-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    @include abs.screen(sm) {
        width: 80px;
        height: 80px;
    }
}
.back-wrap {
    position: absolute;
    top: 10px;
    left: 10px;
    border: 1px solid #fff;
    padding: 10px;
    border-radius: 10px;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    .back-icon {
        color: #fff;
        transition: ease 0.4s;
    }
    .back-text {
        color: #fff;
    }
    &:hover {
        .back-icon {
            transform: translateX(-2px);
        }
    }
}
.user-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    &.user {
        justify-content: flex-start;
    }
    .info-wrap {
        display: flex;
        flex-direction: column;
        gap: 4px;
        color: #fff;
    }
    .name {
        font-size: 1.6rem;
        font-weight: 500;
    }
}
.avatar {
    @include abs.screen(sm) {
        display: none;
    }
}
.table {
    position: relative;
    &::after {
        position: absolute;
        z-index: -1;
        content: '';
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.2),
            rgba(221, 220, 252, 0.2) 40.63%,
            rgba(143, 133, 255, 0.2)
        );
        border-radius: 20px;
        opacity: 0.2;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
    }
    width: 100%;
    margin: 0 auto;
    border-collapse: separate; /* Ngăn cách các ô */
    border-spacing: 16px 20px; /* Khoảng cách giữa các hàng */
    .item-wrap {
        position: relative;
        &.head {
            &::after {
                content: '';
                position: absolute;
                bottom: -4px;
                left: 0;
                right: 0;
                width: 100%;
                height: 2px;
                background-color: #ccc;
            }
        }
    }
    .item {
        &.user {
            text-align: start;
        }
        text-align: center;
    }
    .rank {
        font-size: 2rem;
        padding: 10px;
        @include abs.screen(sm) {
            font-size: 1.6rem;
            padding: 0;
        }
    }
}
