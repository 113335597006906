@use '../../../components/GlobalStyles/abstracts/' as abs;
.footer {
    background-color: #181821;
    padding: 50px 50px 100px 50px;
    .main {
        display: flex;
        flex-direction: column;
        height: 100%;
        color: #fff;
        @include abs.screen(lg) {
            flex-direction: row;
            align-items: center;
            gap: 20px;
        }
        .main-desc {
            margin-top: 40px;
            color: #ccc;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 2rem;
            @include abs.screen(lg) {
                margin-top: 0;
            }
        }
    }
    .list-wrap {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 20px;
        @include abs.screen(lg) {
            justify-content: flex-start;
        }
        .title {
            color: #fff;
            font-size: 1.6rem;
            font-weight: 500;
            margin-bottom: 10px;
        }
        .item {
            color: #a9b3bb;
            font-size: 1.4rem;
            font-weight: 400;
            text-decoration: none;
            list-style-type: none;
            padding: 10px 0px;
            cursor: pointer;
        }
    }
}

.list {
    margin: 0;
    padding: 0;
}
