@use '../../../components/GlobalStyles/abstracts/' as abs;
.modal {
    display: none;
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    &.active {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .content {
        position: absolute;
        animation: fadeIn ease 0.3s;
        z-index: 2;
        width: 60%;
        background-color: #fff;
        height: calc(100vh - 150px);
        overflow: hidden;
        overscroll-behavior: contain;
        border-radius: 16px;
        box-shadow: 0px 0px 11px 0px rgba(53, 48, 48, 0.75);
        -webkit-box-shadow: 0px 0px 11px 0px rgba(53, 48, 48, 0.75);
        -moz-box-shadow: 0px 0px 11px 0px rgba(53, 48, 48, 0.75);
        @include abs.screen(md) {
            width: 90%;
        }
        .close-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 10px;
            top: 10px;
            height: 40px;
            width: 40px;
            background-color: rgba(22, 24, 35, 0.08);
            color: #4c4c4c;
            border-radius: 50%;
            overflow: hidden;
            cursor: pointer;
        }
    }
    .over-lay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(244, 244, 244, 0.8);
        z-index: 1;
    }
}
.body {
    width: 100%;
    height: 100%;
    padding: 20px 30px;
    overflow: hidden auto;
    @include abs.screen(md) {
        padding: 10px;
    }
}

@keyframes fadeIn {
    0% {
        transform: scale(0.6);
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
