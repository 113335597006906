@use '../../components/GlobalStyles/abstracts/' as abs;
.search {
    position: relative;
    width: 420px;
    max-width: 100%;
    height: 40px;
    padding: 0 16px 0 8px;
    border-radius: 20px;
    border: 2px solid rgb(232, 232, 232);
    transition: border-color 0.2s ease;
    display: flex;
    align-items: center;
    &:focus-within {
        border: 2px solid #000;
    }
    @include abs.screen(xl) {
        margin-left: 80px;
    }
    @include abs.screen(md) {
        width: 100%;
        margin-left: 0;
    }
    .btn-search {
        background-color: #fff;
        width: 30px;
        height: 100%;
        border-radius: 50%;
        color: #5e5e5e;
        transition: ease-in-out 0.3s;
        font-size: 1.8rem;
        &:hover {
            color: #000;
        }
    }
    .input {
        flex: 1;
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
        padding: 0 4px;
        caret-color: #444;
    }
}
.btn-sub {
    cursor: pointer;
    padding: 5px 0px 5px 5px;
}
.dropdown {
    animation: fadeIn ease 0.4s;
    width: 420px;
    min-width: 230px;
    min-height: 50px;
    max-height: calc(90vh - 36px);
    padding: 12px 24px;
    font-size: 1.4rem;
    overflow-y: auto !important;
    overscroll-behavior: contain;
    border: 1px solid #ccc;
    box-shadow: 0 -4px 32px #0003;
    transition: all ease-in-out 0.3s;
    border-radius: 10px;
    overflow: hidden;
    color: #1d2129;
    background-color: #fff;
    @include abs.screen(sm) {
        width: calc(100vw - 10px);
    }
    .drop-header {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 0px;
        .search-icon {
            font-size: 1.4rem;
            color: #5e5e5e;
        }
        .head-desc {
            font-size: 1.4rem;
            font-weight: 400;
            color: #5e5e5e;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-8px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
