@use '../../components/GlobalStyles/abstracts/' as abs;
.profile-drop {
    min-width: 300px;
    background: var(--white-color);
    padding: 20px;
    box-shadow: 0 -4px 32px #0003;
    transition: all ease-in-out 0.3s;
    animation: _fadeIn_qhx2q_1 ease 0.3s;
    border-radius: 10px;
    margin: 0;
    overflow: hidden;
    will-change: opacity, transform;
    color: #1d2129;
    transition: all ease-in-out 0.3s;
    animation: fadeIn ease 0.3s;
    .profile-info {
        display: flex;
        align-items: center;
        gap: 16px;
        .profile-avatar {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 50%;
        }
        .profile-user {
            .profile-name {
                font-size: 1.6rem;
                font-weight: 600;
                color: #292929;
            }
            .profile-email {
                margin-top: 8px;
                font-size: 1.4rem;
                color: #757575;
            }
        }
    }
    .profile-list {
        padding-left: 0;
        .profile-item-link {
            font-size: 1.6rem;
            font-weight: 400;
            color: #666;
            display: block;
            padding: 10px 0;
            cursor: pointer;
            &:hover {
                color: #000;
            }
        }
    }
}
.avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    .avatar-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.response {
    display: none;
    @include abs.screen(lg) {
        display: block;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-8px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
