@use '../../../../../components/GlobalStyles/abstracts/' as abs;

.wrap {
    width: 640px;
    margin: 0 auto;
    background-color: transparent;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include abs.screen(lg) {
        width: 500px;
    }
    @include abs.screen(sm) {
        width: 90%;
        margin: 0 auto;
    }
    .title {
        font-size: 1.6rem;
        font-weight: 500;
        margin: 10px 0px;
    }
    .body {
        width: 100%;
        flex: 1;
        border: 1px solid #ccc;
        border-radius: 20px;
        padding: 10px;
        overflow: hidden;
        @include abs.screen(md) {
            margin-bottom: 60px;
        }
    }
}
