.wrap {
    .title {
        font-size: 2.4rem;
        font-weight: 900;
        line-height: 1.4;
        color: #242424;
        margin: 50px 0 32px 0px;
    }
    .desc {
        margin-bottom: 50px;
        color: #292929;
        word-wrap: break-word;
        line-height: var(--line-height, 2);
        font-size: var(--font-size, 1.8rem);
        max-width: 840px;
        font-size: 1.4rem;
    }
}
