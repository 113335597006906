@use '../../../components/GlobalStyles/abstracts/' as abs;
.wrap {
    width: 480px;
    @include abs.screen(sm) {
        width: 100%;
    }
    .title {
        font-size: 2.8rem;
        font-weight: 700;
        margin: 20px 0px;
    }
    .desc {
        width: min(400px, 90%);
        margin: 0 auto;
        font-size: 1.4rem;
        line-height: 1.5;
        margin-bottom: 10px;
    }
}
.form-wrap {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 10px;
    @include abs.screen(sm) {
        width: 100%;
    }
    &.error {
        .input-wrap {
            background-color: #ff00001a;
            border: 1.5px solid var(--invalid-color);
            &:focus-within {
                border: 1.5px solid var(--invalid-color);
            }
        }
    }
    .input-wrap {
        position: relative;
        background: #fff;
        border: 1.5px solid var(--divider-color);
        border-radius: 44px;
        overflow: hidden;
        height: 44px;
        width: 100%;
        display: flex;
        align-items: center;
        transition: ease 0.3s;
        &:focus-within {
            border: 1.5px solid var(--button-color);
        }
        &.disable {
            background-color: #16182329;
        }
        .input,
        .code-input {
            display: block;
            width: 100%;
            padding: 12px 42px 12px 20px;
            border: none;
            outline: none;
            font-size: 1.6rem;
            font-weight: 500;
            background-color: transparent;
        }
        .error {
            position: absolute;
            right: 16px;
            color: var(--invalid-color);
        }
    }
}
.code {
    display: block;
    margin: 10px 0px;
    color: green;
    font-weight: 500;
}
.form-group {
    width: 100%;
    text-align: start;
    margin: 10px 0px;
    .label {
        font-size: 1.4rem;
        font-weight: 600;
        margin: 10px 0 10px 8px;
        display: block;
    }
    .error-text {
        margin: 8px 0 8px 8px;
        color: var(--invalid-color);
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.5;
    }
}

.code-btn {
    padding: 20px 0px;
    &.disable {
        color: #757575;
        background-color: #ccc;
    }
}
.next-btn {
    width: 100%;
    margin-top: 20px;
    padding: 12px 0px;
}
.sub-desc {
    margin-left: auto;
    margin-right: auto;
    padding: 16px;
    width: min(400px, 100%);
    font-size: 1.1rem;
    color: #666;
}
.error-wrap {
    animation: slide ease 0.3s;
    .error-item {
        display: flex;
        align-items: center;
    }
}

// password
.modal-wrap {
    width: 420px;
    margin: 0 auto;
    .modal-title {
        font-size: 2.8rem;
        font-weight: 700;
        margin: 20px 0px;
    }
    .modal-desc {
        margin-top: 8px;
        font-size: 1.4rem;
        opacity: 0.9;
        margin-bottom: 20px;
    }
    .modal-form-group {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding-bottom: 30px;
        .modal-label {
            font-size: 1.4rem;
            font-weight: 500;
            margin: 10px 0 10px 8px;
            display: block;
        }
        .modal-input-wrap {
            width: 100%;
            position: relative;
            background: #fff;
            border: 1.5px solid #dee3e9;
            border-radius: 44px;
            overflow: hidden;
            height: 44px;
            display: flex;
            &.error {
                border: 1px solid red;
                background-color: #ff00001a;
            }
            &:focus-within {
                border: 1px solid #1dbfaf;
            }
            .eye-icon {
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                cursor: pointer;
            }
        }
        .modal-input {
            width: 100%;
            display: block;
            width: 100%;
            padding: 12px 42px 12px 20px;
            border: none;
            outline: none;
            font-size: 1.4rem;
            background-color: transparent;
        }
    }
    .btn {
        color: #fff;
        background-color: #1dbfaf;
        border: none;
        width: 100%;
        padding: 10px 0px;
        border-radius: 999px;
        overflow: hidden;
        &.adopt {
            cursor: not-allowed;
            opacity: 0.4;
            &:hover {
                opacity: 0.4;
            }
        }
        &:hover {
            opacity: 0.7;
        }
    }
    .btn-upload {
        color: #fff;
        background-color: #1dbfaf;
        border: none;
        width: 100%;
        padding: 10px 0px;
        border-radius: 999px;
        overflow: hidden;
        &.adopt {
            cursor: not-allowed;
            opacity: 0.4;
            &:hover {
                opacity: 0.4;
            }
        }
        &:hover {
            opacity: 0.7;
        }
    }
}

.error-message {
    display: flex;
    align-items: center;
    gap: 10px;
    color: red;
    animation: slide ease 0.3s;
    margin-bottom: 10px;
}
.error-shake {
    position: absolute;
    right: 16px;
    top: 30%;
    color: var(--invalid-color);
    animation: shake ease-in-out 0.4s;
}

@keyframes slide {
    0% {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-4px);
    }
    100% {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
    }
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-10px);
    }
    50% {
        transform: translateX(10px);
    }
    75% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(0);
    }
}
