@use '../../components/GlobalStyles/abstracts/' as abs;

.content {
    margin-top: 66px;
    min-height: 100vh;
    margin-bottom: 20px;
    .body {
        padding: 0px 32px 0px 10px;
        margin-top: 18px;
        @include abs.screen(md) {
            padding: 0px 10px;
        }
    }
}
.nav-mobile {
    display: none;
    @include abs.screen(md) {
        display: block;
    }
}
