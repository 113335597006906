@use '../../components/GlobalStyles/abstracts/' as abs;
.wrap {
    width: 100vw;
    min-height: 100vh;
    background-color: #0093e9;
    background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
    display: flex;
    .body {
        margin: auto;
        width: 70%;
        height: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include abs.screen(xxl) {
            width: 80%;
        }
        @include abs.screen(xl) {
            width: 100%;
        }
        @include abs.screen(lg) {
            width: 80%;
            margin: 40px auto;
            display: block;
        }
        @include abs.screen(sm) {
            width: 100%;
        }
        .title {
            margin-top: 20px;
            font-size: 4rem;
            font-weight: 500;
            color: blue;
        }
        .desc {
            color: #ede7e7;
            font-size: 1.8rem;
            font-weight: 400;
        }
    }
}
