@use '../../../components/GlobalStyles/abstracts/' as abs;
.footer {
    position: fixed;
    bottom: 0;
    height: 50px;
    width: 100%;
    backdrop-filter: blur(32px);
    background-color: #191d1e;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px;
    @include abs.screen(sm) {
        padding: 0px 4px;
    }
    .unit {
        min-width: 100px;
        font-size: 1.4rem;
        color: hsla(0, 0%, 100%, 0.8);
        @include abs.screen(md) {
            display: none;
        }
    }
    .lesson {
        display: flex;
        align-items: center;
        gap: 10px;
        .btn-wrap {
            transition: ease 0.4s;
            width: 160px;
            padding: 8px 0;
            display: flex;
            gap: 4px;
            color: #fff;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            user-select: none;
            border-radius: 10px; /* Áp dụng border-radius cho phần tử */
            border: 2px solid transparent;
            @include abs.screen(sm) {
                width: 130px;
                gap: 2px;
            }
        }
        .btn-wrap.outlined {
            background: linear-gradient(#191d1e, #191d1e) padding-box,
                linear-gradient(141.34deg, #5ebbff 0%, #a174ff 91.11%) border-box; /* Gradient cho border */
        }
        .btn-wrap.active {
            background: linear-gradient(141.34deg, #5ebbff 0%, #a174ff 91.11%) border-box;
            &:hover {
                opacity: 0.7;
            }
        }
        .btn {
            font-size: 1.6rem;
            font-weight: 500;
            background-color: transparent;
            color: #fff;
            @include abs.screen(sm) {
                font-size: 1.4rem;
            }
        }
        .btn-prev {
            &:hover {
                border: 2px solid transparent;
                background: linear-gradient(#191d1e, #191d1e) padding-box,
                    linear-gradient(141.34deg, #5ebbff 0%, #a174ff 91.11%) border-box; /* Gradient cho border */
            }
        }
    }
    .side-bar {
        border-radius: 50%;
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #292d35;
        transition: background-color 0.3s ease;
        cursor: pointer;
    }
    .ask {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: #292d35;
        padding: 10px;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        color: #fff;
    }
    .unit-wrap {
        color: #fff;
        font-size: 1.6rem;
        font-weight: 500;
        border: 1px solid #ccc;
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }
}
