@use '../../components/GlobalStyles/abstracts/' as abs;
.nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 20px 0px 20px 20px;
    @include abs.screen(lg) {
        gap: 10px;
        padding: 10px;
    }
    @include abs.screen(md) {
        padding: 0;
        padding-left: 5px;
    }
}
.btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding: 10px;
    width: 100%;
    border-radius: 10px;
    background-color: #262d3b;
    border: none;
    color: #fff;
    transition: ease 0.2s;
    @include abs.screen(lg) {
        gap: 10px;
    }
    @include abs.screen(md) {
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    &:hover {
        background-color: #807c7c;
    }
    .icon {
        display: inline-block;
        width: 28px;
        height: 30px;
        color: #fff;
    }
    .name {
        color: #fff;
        @include abs.screen(md) {
            display: none;
        }
    }
}
.title-nav {
    font-size: 1.8rem;
    font-weight: 300;
    color: #fff;
    margin-bottom: 20px;
}

.body {
    border-left: 1px solid #ccc;
    min-height: calc(100vh - 150px);
}
.wrapper {
    background-color: #262d3b;
    min-height: 100vh;
    overflow-x: hidden;
}
.content {
    padding-top: 50px;
}
