@use '../../components/GlobalStyles/abstracts/' as abs;
.wrap {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    bottom: 1px solid #ccc;
    margin-top: 20px;
    padding-bottom: 12px;
    margin-bottom: 20px;
    border-bottom: 1px solid #d0d7de;
    @include abs.screen(md) {
        flex-direction: column;
    }
}
.img-link {
    margin-right: 24px;
    width: 228px;
    height: 128px;
    flex-shrink: 0;
    border-radius: 16px;
    overflow: hidden;
    @include abs.screen(md) {
        width: 100%;
        height: auto;
    }
    .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.info {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    align-items: flex-start;
    @include abs.screen(md) {
        width: 100%;
    }
    .title {
        margin-top: 10px;
        font-size: 1.6rem;
        font-weight: 600;
    }
    .desc {
        margin-top: 8px;
        font-size: 1.4rem;
        line-height: 2.2rem;
        overflow: hidden;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
    }
    .wrap-info {
        margin-bottom: auto;
    }
}
