@use '../../../../components/GlobalStyles/abstracts/' as abs;

.wrap {
    margin: 0px 100px;
    @include abs.screen(lg) {
        margin: 0;
    }
    .title {
        margin: 40px 0px;
    }
    .content {
        color: #292929;
        background-color: #f2f9ff;
        padding: 16px;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 10px;
    }
    .choices {
        .choices-sub {
            font-size: 1.4rem;
            font-weight: 400;
            margin: 16px 0px;
        }
    }
    .list {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 0;
        padding: 0;
        .item {
            transition: ease 0.4s;
            cursor: pointer;
            background-color: #f0f1f4;
            padding: 14px;
            border-radius: 10px;
            border: 1px solid transparent;
        }
        .item.active {
            border: 1px solid #678cff;
            &.error {
                border: 1px solid red;
            }
            &.correct {
                border: 1px solid green;
            }
        }
    }
    .subwrap {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }
}
.correct-alert,
.error-alert {
    font-size: 1.4rem;
    font-weight: 600;
    display: none;
    &.active {
        display: inline-block;
        animation: fadeIn ease 0.4s;
    }
}
.correct-alert {
    color: green;
}
.error-alert {
    color: red;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
