@use '../../components/GlobalStyles/abstracts/' as abs;
.wrap {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding: 20px;
    @include abs.screen(sm) {
        flex-direction: column;
    }
    .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        .head {
            display: flex;
            align-items: center;
            gap: 20px;
            color: #fff;
            .title {
                font-size: 1.8rem;
                font-weight: 600;
            }
        }
        .quantity {
            font-size: 3rem;
            font-weight: 600;
            color: #fff;
        }
        .link {
            color: yellow;
            font-size: 1.6rem;
            font-weight: 600;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.right {
    flex: 1;
    height: 150px;
    @include abs.screen(sm) {
        width: 100%;
    }
}
.info-wrap {
    background-color: #fff !important;
    border-radius: 10px;
}
