.wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    padding: 0 28px;
    height: var(--header-height);
    background-color: #fff;
    font-size: 1.4rem;
}
.wrap.border {
    border-bottom: 1px solid #e8ebed;
}
.left {
    display: flex;
    align-items: center;
    gap: 20px;
}
.public-right {
    display: flex;
    align-items: center;
    width: 200px;
}
.btn-wrap {
    display: flex;
    align-items: center;
}
.back-wrap {
    .icon {
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.4rem;
        color: #808990;
    }
    .back-desc {
        margin-left: 4px;
    }
    .font-icon {
        display: inline-block;
        transition: ease 0.4s;
    }
    &:hover {
        .font-icon {
            transform: translateX(-4px);
        }
    }
}
.right {
    display: flex;
    align-items: center;
    gap: 20px;
}
