@use '../../components/GlobalStyles/abstracts/' as abs;

.wrap {
    padding: 10px 0px;
    word-break: break-word; /* Breaks long words */
    overflow-wrap: break-word; /* Ensures words will wrap onto the next line */
    white-space: normal; /* Allows text to wrap normally */
    width: min(1000px, 100%);
    margin: 0 auto;
}
.title {
    margin: 10px 0px;
    font-size: 4rem;
    font-weight: 500;
    @include abs.screen(sm) {
        font-size: 2.6rem;
    }
}
.user-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0px;
    .left {
        display: flex;
        align-items: center;
        gap: 20px;
        @include abs.screen(sm) {
            gap: 10px;
        }
        .avatar {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            object-fit: cover;
            @include abs.screen(sm) {
                width: 40px;
                height: 40px;
            }
        }
        .info {
            display: flex;
            flex-direction: column;
            gap: 10px;
            @include abs.screen(sm) {
                gap: 4px;
            }
            .name {
                font-size: 2rem;
                font-weight: 500;
                @include abs.screen(sm) {
                    font-size: 1.8rem;
                }
            }
            .timer {
                font-size: 1.6rem;
                font-weight: 400;
                color: #4f4a4a;
                @include abs.screen(sm) {
                    font-size: 1.4rem;
                }
            }
        }
    }
    .utils {
        cursor: pointer;
    }
}
